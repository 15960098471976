import { AuthRecord, UserInfoRecord, Plan, PlanEnum } from '../../common/types';
import { fromJS } from 'immutable';
import localStorage from '../components/personsList/localstorage';
import {
  selectIsOnboarding,
  selectViewerPlan,
  selectViewerPlanId,
  selectIsTemporaryLoggedIn,
} from 'sp/common/auth/selectors';
import { buildCheckoutLink, UserData, Cashier } from '@tipranks/checkout-link';
import { AddOn } from '../../common/api-adapter';

function getSku(isUpgrade: boolean, plan: Plan): number {
  return payment.getIn(['plan', isUpgrade ? 'upgrade' : plan, 'sku']);
}

function getFsSubscription (sku: number): string {
  switch (sku) {
    case 3324380:
      return "nasdaq-premium-yearly";
      break;
    case 3352814:
      return "nasdaq-premium-quarterly";
      break;
    case 3324376:
      return "nasdaq-premium-2-year";
      break;
  }
  return "nasdaq-premium-quarterly";
}

const payment = fromJS({
  storeId: process.env.THEME === 'nasdaq' ? 548795 : 477080,
  lang: 'ENGLISH',
  currency: 'USD',
  plan: {
    premium: {
      sku: 3256820,
    },
    ultimate: {
      sku: 3249312,
    },
    upgrade: {
      // coupon: 'TRUPGDIS2',
      sku: 3307180,
    },
    nasdaqMonthly: {
      sku: 3324378,
    },
    nasdaqQuarterly: {
      sku: 3352814,
    },
    nasdaqYearly: {
      sku: 3324380,
    },
    nasdaqBiyearly: {
      sku: 3324376,
    },
  },
});

const selectEmail = (auth: AuthRecord) => {
  const email = auth
    .get('users')
    .get('viewer')
    .get('loggedInUser')
    .get('email');

  // onboarding users have some sort of uid in the email.
  // selectEmail is used by upgrading popups to prefill
  // the email input on the page where the user fills out credit card information
  // I wanted to put a check for '@', but this feels more stable.
  if (selectIsOnboarding()) return '';
  return email;
};

export const normalizeDate = i_date => {
  var date = new Date(parseInt(i_date));
  return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
};

const getLastPromotionTrigger = () => localStorage.getItem('lastLockedFeature');

const toQueryStringPart = obj => a => `${encodeURIComponent(a)}=${encodeURIComponent(obj[a])}`;

const queryString = (url, args) =>
  `${url}?${Object.keys(args)
    .map(toQueryStringPart(args))
    .join('&')}`;

export function planToString(plan: PlanEnum = PlanEnum.Open, isTemporary = false): Plan {
  if (isTemporary && plan === PlanEnum.Free) {
    return 'open';
  }
  return {
    [PlanEnum.Open]: 'open',
    [PlanEnum.Free]: 'free',
    [PlanEnum.Premium]: 'premium',
    [PlanEnum.DashboardPremium]: 'premium',
    [PlanEnum.Ultimate]: 'ultimate',
    [PlanEnum.DashboardUltimate]: 'ultimate',
  }[plan] as Plan;
}

const getBluesnapCustomParams = (auth: AuthRecord, planName: string, override: any = {}) => {
  const refUrl = localStorage.getItem('refUrl');
  const userReferrer = (refUrl ? refUrl + '+' : '') + localStorage.getItem('referrer');
  const userFirstVisit = localStorage.getItem('firstVisit');
  const tipranksEmail = selectEmail(auth);
  // TODO figure out why auth.userInfo.email contains a UID which IS NOT A FUCKING EMAIL!!!!!!!!!!!!!!!!!!!!!1
  const lastLockedFeature = getLastPromotionTrigger();
  // const planName = userPlanName(auth);

  const tid = localStorage.getItem('tid');
  const utm = localStorage.getItem('utmChain') || '';

  const ret = {
    custom1: tid,
    custom2: encodeURIComponent(userReferrer),
    custom3: lastLockedFeature,
    custom4: tipranksEmail,
    custom5: planToString(selectViewerPlanId(auth), selectIsTemporaryLoggedIn()),
    custom6: userFirstVisit,
    custom7: utm.slice(0, 1000),
    custom8: localStorage.getItem('lastLockedFeatureURL'),
    OnboardingAction: localStorage.getItem('OnboardingAction'),
  };

  for (const k in ret) {
    ret[k] = ret[k] || null;
  }

  return { ...ret, ...override };
};


const getFSCustomParams = (auth: AuthRecord, planName: string, override: any = {}) => {
  const refUrl = localStorage.getItem('refUrl');
  // TODO figure out why auth.userInfo.email contains a UID which IS NOT A FUCKING EMAIL!!!!!!!!!!!!!!!!!!!!!1
  const lastLockedFeature = getLastPromotionTrigger();
  const ret = {
    custom3: lastLockedFeature,
    custom18: "nasdaq"
  };

  for (const k in ret) {
    ret[k] = ret[k] || null;
  }

  return { ...ret, ...override };
};

export const blueSnapPaymentParams = (auth: AuthRecord, plan: Plan, isUpgrade = false, override: any = {}) => ({
  storeId: payment.getIn(['storeId']),
  firstname: '', // TODO this doesn't exist any more... (auth.userInfo ? auth.userInfo.firstName : '') || '',
  lastname: '', // TODO this doesn't exist any more... (auth.userInfo ? auth.userInfo.lastName : '') || '',
  email: selectEmail(auth),
  language: payment.getIn(['lang']),
  currency: payment.getIn(['currency']),
  coupon: payment.getIn(['plan', isUpgrade ? 'upgrade' : plan, 'coupon']) || '',
  [`sku${getSku(isUpgrade, plan)}`]: 1,
  ...getBluesnapCustomParams(auth, plan),
});

export const fsPaymentParams = (auth: AuthRecord, plan: Plan, isUpgrade = false, override: any = {}) => ({
  email: selectEmail(auth),
  coupon: payment.getIn(['plan', isUpgrade ? 'upgrade' : plan, 'coupon']) || '',
  subscription: getFsSubscription(getSku(isUpgrade, plan)),
  ...getFSCustomParams(auth, plan),
});

interface BuildLinkConfig {
  cashier: Cashier;
  featureOrOrigin?: string;
  pageName?: string;
}
export const getBlueSnapPaymentUrl = (auth: AuthRecord, plan: Plan, isUpgrade = false, override: any = {}, buildLinkConfig?: BuildLinkConfig ) => {
  if (process.env.THEME !== 'nasdaq') {
    const loggedIn = auth.get('users').get('viewer').get('loggedInUser');
    const _auth: UserData = {
      isTemporary: selectIsOnboarding(),
      planId: Number(loggedIn.get('planId')),
      addOns: (loggedIn.get('addOns').toJS()) as AddOn[],
      userInfo: {
        email: loggedIn.get('email'),
        firstName: loggedIn.get('name'),
        userUid: loggedIn.get('id'),
      }
    }

    const _buildLinkConfig: { custom3?: string, custom18?: string } = {}
    if (buildLinkConfig) {      
      if(buildLinkConfig.featureOrOrigin) {
        _buildLinkConfig.custom3 = buildLinkConfig.featureOrOrigin;
      }
      if (buildLinkConfig.pageName) {
        _buildLinkConfig.custom18 = buildLinkConfig.pageName
      }
    }

    const custom3Spread: { custom3: string } | {} = _buildLinkConfig.custom3 ? { custom3: _buildLinkConfig.custom3 } : {};
    const custom18Spread: { custom18: string } | {} = _buildLinkConfig.custom18 ? { custom18: _buildLinkConfig.custom18 }: {}

    return buildCheckoutLink({
      auth: _auth,
      sku: String(getSku(isUpgrade, plan)),
      cashier: (buildLinkConfig && buildLinkConfig.cashier) || 'direct',
      googleAnalyticsCookieId: getGoogleAnalyticsCookieId(),
      deviceType: 'unknown',
      overrides: { ...custom3Spread, ...custom18Spread },
    });
  }
  return `${queryString('https://checkout.bluesnap.com/buynow/checkout/', {
    ...blueSnapPaymentParams(auth, plan, isUpgrade, override),
  })}${process.env.THEME === 'nasdaq' ? `&backtosellerurl=${location.origin}/smart-portfolio/thank-you` : ''}`;
};

export const getFSPaymentUrl = (auth: AuthRecord, plan: Plan, isUpgrade = false, override: any = {}, buildLinkConfig?: BuildLinkConfig ) => {
  if (process.env.THEME !== 'nasdaq') {
    const loggedIn = auth.get('users').get('viewer').get('loggedInUser');
    const _auth: UserData = {
      isTemporary: selectIsOnboarding(),
      planId: Number(loggedIn.get('planId')),
      addOns: (loggedIn.get('addOns').toJS()) as AddOn[],
      userInfo: {
        email: loggedIn.get('email'),
        firstName: loggedIn.get('name'),
        userUid: loggedIn.get('id'),
      }
    }

    const _buildLinkConfig: { custom3?: string, custom18?: string } = {}
    if (buildLinkConfig) {      
      if(buildLinkConfig.featureOrOrigin) {
        _buildLinkConfig.custom3 = buildLinkConfig.featureOrOrigin;
      }
      if (buildLinkConfig.pageName) {
        _buildLinkConfig.custom18 = buildLinkConfig.pageName
      }
    }

    const custom3Spread: { custom3: string } | {} = _buildLinkConfig.custom3 ? { custom3: _buildLinkConfig.custom3 } : {};
    const custom18Spread: { custom18: string } | {} = _buildLinkConfig.custom18 ? { custom18: _buildLinkConfig.custom18 }: {}

    return buildCheckoutLink({
      auth: _auth,
      sku: String(getFsSubscription(getSku(isUpgrade, plan))),
      cashier: (buildLinkConfig && buildLinkConfig.cashier) || 'direct',
      googleAnalyticsCookieId: getGoogleAnalyticsCookieId(),
      deviceType: 'unknown',
      overrides: { ...custom3Spread, ...custom18Spread },
    });
  }
  return `${queryString(`https://${window.location.hostname.includes("qc") ? "preprod4" : "www"}.tipranks.com/checkout/nasdaq/`, {
    ...fsPaymentParams(auth, plan, isUpgrade, override),
  })}`;
};


export function getSpCurrentPageName(str: string) {
  if (typeof window === 'undefined') { return 'ssr-sp'}
  try {
    const pathName = window.location.pathname;
    const pathNameWithoutBeginningSlash = pathName[0] === '/' ? pathName.slice(1) : pathName;
    const pageName = pathNameWithoutBeginningSlash.replace(/\//g, '_')
    return pageName;
  } catch {
    return 'sp-unknown-page';
  }
}

function getCheckoutVariant() {
  if (typeof window === 'undefined') { return 'bluesnap' }
  try {
    const cookiesStr = document.cookie;
    const cookiesArr = cookiesStr.split('; ');
    const encodedExperiment = cookiesArr.find(x => x.startsWith('tipranks-experiments'))
    const decodedExperimentsObjectValueAsJSON = decodeURIComponent(String(encodedExperiment)).split('=')[1];
    const parsedDecodedExperimentsObjectValue = JSON.parse(decodedExperimentsObjectValueAsJSON);
    const experimentsList = parsedDecodedExperimentsObjectValue.Experiments;
    const checkoutExperimentObject = experimentsList.find(x => x.Name === 'checkout-page-variant');
    const variant = checkoutExperimentObject.Variant;
    return variant === 'tipranks' ? 'tipranks' : 'bluesnap'
  } catch {
    return 'bluesnap';
  }
}

function getGoogleAnalyticsCookieId() {
  if (typeof window === 'undefined') { return 'ssr' }
  
  try {
    const cookiesStr = document.cookie;
    const cookiesArr = cookiesStr.split('; ');
    const rawEncodedGoogleAnalyticsId = cookiesArr.find(x => String(x).startsWith('_ga'))
    const rawSplittedEncodedGoogleAnalyticsId = (String(rawEncodedGoogleAnalyticsId).split('_ga=')[1] || '')
    return rawSplittedEncodedGoogleAnalyticsId.slice(6)
    
  } catch {
    return 'failed-to-parse-google-analytics-id'
  }
}