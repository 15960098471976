import { fromJS, is, List, Iterable } from 'immutable';
import * as rx from 'rx-lite';;
import { Person, Stock } from 'sp/common/records';

import { hasPayed } from '../../common/auth/selectors';
import { AuthRecord, Plan, Routes } from '../../common/types';
import { blueSnapPaymentParams, fsPaymentParams } from '../tr-payment/index';
import { personsListConfig } from 'sp/browser/components/personsList/conf';
import { personsListMsg } from 'sp/browser/components/personsList/en';
import { getTickerUrl, getSectorText } from 'sp/common/lib/utils';
import { MediaQuery, MediaQueryKey } from 'sp/common/lib/mediaQuery';

// TODO once nasdaq will fix their https issues, switch back to HTTPS
export const createNasdaqLink = (rest = '') => `http://nasdaq.com/${rest}`;
export const createWebsiteLink = (rest = '') => // TODO make this robust
  window.location.href.includes('preprod5')
    ? `https://preprod5.tipranks.com/${rest}`
    : `https://tipranks.com/${rest}`;

export const domain = () =>
  process.env.THEME === 'web' ? '' : 'https://www.tipranks.com';

export const premiumUpgradeUrl = (auth: AuthRecord, plan: Plan) =>
  appRoute(
    'fsPayment',
    fsPaymentParams(auth, plan, hasPayed(auth)),
  );

export const premiumUpgradeUrl2 = (auth: AuthRecord, plan: Plan) =>
  appRoute('fsPayment', fsPaymentParams(auth, plan));

export const percentField = (percent, toFixed = 2) => {
  if (percent === null) return '--';
  return (percent * 100).toFixed(toFixed) + '%';
};

// In the my account version, for some godforsaken reason, .route-inner-header
// started disappearing after the details call ended. literally, just becoming white.
// it was still clickable though, and forcing reflow made it "reappear".
// IE 11 be damned, we didn't figure out why it was doing that and resorted to simply
// force the reflow.
export const isShittyBrowser =
  typeof window !== 'undefined' &&
  window.navigator.userAgent.indexOf('Trident') !== -1;

// I started writing a function to solve cleverly a responsiveness problem.
// We have a resolution range, 360 - 1920. We have an element who's width is fluid, in 360 it's 18px, in 1920 it's 24px.
// This function recieves a resolution number, say, 768, and returns a number between 18 to 24 that correspondes with 768.
// This makes it easy to make fluid designs in JS.
// BUT THEN~! I realized I didn't need this, because the designer didn't make all of my elements with the same size,
// and that's why it looked weird, and I don't need all this fluid shenanigans.
// I have never ran this, so check your results.
// export const getRangeToRangeMapper = (minRange1, maxRange1, minRange2, maxRange2) => (range1: number): number => {
//    const range1Size = maxRange1 - minRange1;
//    const proportion = (value - minRange) / ;
//    return proportion * range2Size + minRange2;
// }

export const pxToX = x => (num, base = 16) => {
  return Array.isArray(num)
    ? num.map(n => pxToRem(n, base)).join(' ')
    : `${(num / base).toFixed(4)}${x}`;
};

export const pxToRem = pxToX('rem');
export const pxToEm = pxToX('em');

export const kebabCase = str =>
  str
    .trim()
    .replace(/ /g, '-')
    .toLowerCase();

// ES5 for 11% performance increase!!!
export const compose = function () {
  const fns = arguments;
  return function () {
    let result = fns[0].apply(null, arguments);
    for (let i = 1, len = fns.length; i < len; i++) {
      result = fns[i](result);
    }
    return result;
  };
};

export const maybeExecute = (maybeFn, ...args) =>
  typeof maybeFn === 'function' ? maybeFn(...args) : maybeFn;

// checks if a string is a valid HTML tagName
const tagNameCache = {};
export const isValidTagName = str => {
  if (tagNameCache[str]) {
    return tagNameCache[str];
  }

  const isValid =
    document.createElement(str).toString() !== '[object HTMLUnknownElement]';
  tagNameCache[str] = isValid;
  return isValid;
};

// splits array into an array of arrays of given size (similar to _.chunk)
export const splitArray = (a, size) => {
  const ret = [] as any;
  for (let i = 0, len = a.length; i < len; i += size) {
    ret.push(a.slice(i, i + size));
  }
  return ret;
};

export let isMedia = {
  test(str) {
    if (typeof window === 'undefined')
      throw new Error(
        "Shouldn't have got here in server-side rendering, sanity.",
      );
    return window.matchMedia && Boolean(window.matchMedia(str).matches);
  },
};

// is element in current viewport
export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  const { innerWidth: width, innerHeight: height } = window;
  return rect.width + rect.left < width - 17 && rect.height + rect.top < height;
};

// react html injection
export const html = str => ({
  dangerouslySetInnerHTML: { __html: str },
});

export const groupBy = (arr, prop) => {
  const ret = {};
  for (let i = 0, len = arr.length; i < len; i++) {
    const el = arr[i];
    const elProp = el[prop];
    const group = (ret[elProp] = ret[elProp] || []);
    group.push(el);
  }
  return ret;
};

export const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return { x: undefined, y: undefined };
  }
  var doc = document.documentElement;
  var x = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  var y = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  return { x, y };
};

export const retry = (fn, retries = 3, defaultCancel = () => { }) => {
  let cancel = defaultCancel;
  var res = Promise.resolve()
    .then(_ => {
      const result = fn();
      try {
        if (typeof result.cancel === 'function') {
          cancel = result.cancel;
        }
      } catch (e) {
        /* if .cancel is a getter and it threw, meh. */
      }
      return result;
    })
    .catch(err => {
      if (retries === 1) {
        console.error(err);
        throw err;
      }
      return retry(fn, retries - 1);
    });
  res.cancel = cancel;
  return res;
};

// sync version
// previously tryCatch.sync
export const tryCatchSync = (handler = defaultErrorHandler) => (
  target,
  key,
  descriptor,
) => {
  const { value: fn } = descriptor;
  descriptor.value = function () {
    try {
      return fn.apply(this, arguments);
    } catch (err) {
      return maybeExecute(handler, err);
    }
  };

  descriptor.value.displayName = fn.name;

  return descriptor;
};

export const get = (obj, path) => {
  if (typeof path === 'string') {
    path = path.split('.');
  }

  const tryCatcher = tryCatchFn();

  const loop = tryCatcher(() => {
    let ret = obj;
    for (let i = 0, len = path.length; i < len; i++) {
      ret = ret[path[i]];
      if (typeof ret === 'undefined') {
        break;
      }
    }

    return ret;
  });

  return loop();
};

export const waitForValue = (getter, interval = 500, timeout = 5000) =>
  rx.Observable
    .interval(interval)
    .map(getter)
    .take(timeout / interval)
    .skipWhile(x => !x)
    .take(1)
    .toPromise();

// from utils.js, left here for posterity sake, can't find usage.
// export const getUrlSearchParams = () => {
//   if (typeof window === 'undefined') {
export //     return {};
  //   }

  //   var match,
  //     pl = /\+/g,  // Regex for replacing addition symbol with a space
  //     search = /([^&=]+)=?([^&]*)/g,
  //     decode = s => decodeURIComponent(s.replace(pl, " ")),
  //     query = window.location.search.substring(1);

  //   let urlParams = {};
  //   while (match = search.exec(query)) {
  //     urlParams[decode(match[1])] = decode(match[2]);
  //   }

  //   getUrlSearchParams.urlParams = urlParams;
  //   return urlParams;
  // };

  const isObject = arg => typeof arg === 'object';

const iterateObject = (obj, cb) => {
  const keys = Object.keys(obj);
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i];
    cb(obj[key], key, obj);
  }
};

export const traverse = (obj, cb, context = '') => {
  iterateObject(obj, (val, key) => {
    const path = context ? context + '.' + key : key;
    const isObj = isObject(val);

    cb(val, key, path, obj, isObj);
    if (isObj) {
      // obj[key] instead of val to take changes as we traverse into account
      traverse(obj[key], cb, path);
    }
  });
};

export const setCESnapshotName = name => {
  if (typeof window !== 'undefined') {
    window['CE_SNAPSHOT_NAME'] = name;
  }
};

export const __hotReload = true;

// --- from personsList

export const instanceOf = (obj, type) =>
  obj.get('instanceOf').some(t => t === type);

// resolve the selected option or fallback to default
export const resolveFn = (selected, options, defaultFn) => () =>
  de((options[selected] || defaultFn)(selected));

const defaultErrorHandler = e => {
  throw e;
};

// function decorator
export const tryCatch = handler => (target, key, descriptor) => {
  const { value: fn } = descriptor;
  descriptor.value = async function (...args) {
    try {
      return fn.apply(this, args);
    } catch (err) {
      return maybeExecute(handler, err);
    }
  };
  return descriptor;
};

// sync version
export const tryCatchcSync = (handler = defaultErrorHandler) => (
  target,
  key,
  descriptor,
) => {
  const { value: fn } = descriptor;
  descriptor.value = function (...args) {
    try {
      return fn.apply(this, args);
    } catch (err) {
      return maybeExecute(handler, err);
    }
  };

  descriptor.value.displayName = fn.name;

  return descriptor;
};

// non-decorator version
export const tryCatchFn = (handler = defaultErrorHandler) => fn =>
  function (...args) {
    try {
      return fn.apply(this, args);
    } catch (err) {
      return maybeExecute(handler, err);
    }
  };

export const markdown = str =>
  `<span class="markdown">${str
    .replace(/(?:\r\n|\r|\n)/g, '<br />')
    .replace(/\*([^*]*)\*/g, '<strong class="markdown-strong">$1</strong>')
    .replace(
      /#([^#]*)#/g,
      '<strong class="markdown-bigger">$1</strong>',
  )}</span>`;

export const safeGet = (...args) => {
  const result = (get as any)(...args);
  return Iterable.isIterable(result) ? result : fromJS(result);
};

// map db plan id to client side plan id
export const planId = planId =>
  personsListConfig.getIn([
    'plans',
    {
      0: 0,
      1: 1,
      2: 3,
      4: 3,
      3: 2,
      5: 3,
      6: 2,
    }[planId] || 0,
  ]);

export const mediaMinWidth = min => `@media (min-width: ${min}rem)`;

export const personTypeImgSrc = type => `/assets/img/person-icons/${type}.png`;

const sectorImgSrc = sectorId =>
  sectorId !== -1 ? `/assets/img/sector-icons/${sectorId}.png` : null;

const getRandomArbitrary = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

const imgSrcString = (str, ...args) => {
  if (typeof str !== 'string') {
    return '';
  }
  return (
    {
      radio: '/images/radio.png',
      activeRadio: '/images/radio-hf.png',
      filtersBtn: '/images/filter-btn.png',
      back: '/images/back.png',
      whiteLock: '/images/white-lock.png',
      loader: '/images/loader.gif',
      emailAlertsIcon: '/images/upgrade-email-alerts.png',
      featureIcon: '/images/plans/feature.png',
      subFeatureIcon: '/images/plans/circle.png',
      info: '/images/info.png',
      delete: '/assets/img/delete.png',
      analystProfileFallback: '/assets/img/analystProfileFallback.png',
      hedgefundProfileFallback: '/assets/img/hedgefundProfileFallback.png',
    }[str] || `/assets/img/${str.replace('.png', '')}.png`
  );
};

export const px = x => `${x}px`;

export const percent = (x, total = 100) => `${x / total * 100}%`;

export const border = (color, size = px(1), style = 'solid') =>
  `${size} ${style} ${color}`;

export const padding = (...args) => args.join(' ');

export const em = (x, total = 16) => `${x / total}em`;

export const font = family =>
  ({
    'Proxima Nova Alt Light': 'Proxima Nova Alt Light',
    'Proxima Nova Alt Semibold': 'Proxima Nova Alt Semibold',
    'Proxima Nova Alt Bold': 'Proxima Nova Alt Bold',
    'Proxima Nova Alt Regular': 'Proxima Nova Alt',
  }[family] || console.warn(`${family} is not a font name`));

export const url = src => `url(${src})`;

export const sectorId = obj =>
  cond(
    [obj instanceof Stock, () => obj.get('sector')],

    [obj instanceof Person, () => sectorId(obj.get('firm'))],
  );

export const detail = (obj, detail) =>
  cond(
    [detail === 'sector', () => getSectorText[sectorId(obj)]],

    () => obj.get(detail),
  );

export const totalExperts = (person, stats) =>
  ({
    analyst: get(stats, 'analystsTotal'),
    blogger: get(stats, 'bloggersTotal'),
    hedgefund: get(stats, 'hedgefundsTotal'),
    insider: get(stats, 'insidersTotal'),
    unknown: 0,
  }[stats ? person.get('type') : 'unknown']);

const totalExpertsSector = (stats, sectorId) =>
  stats.getIn(['sectorsTotal', sectorId]);

export const personRating = (person, stats, type = 'expertType') =>
  cond(
    [
      type === 'expertType',
      () =>
        person.get('rank') > -1
          ? 1 - person.get('rank') / totalExperts(person, stats)
          : -1,
    ],

    [
      type === 'sector',
      () =>
        person.get('rankSector') > -1
          ? 1 -
          person.get('rankSector') /
          totalExpertsSector(stats, sectorId(person))
          : -1,
    ],
  );

export const personType = (person, isPlural = false) =>
  `${personsListMsg.personTypes[person.get('type')]}${isPlural ? 's' : ''}`;

const normalizePostfixSteps = arr => ({
  num: arr[0],
  postfix: arr[1],
  fullPostfix: ` ${arr[2]}`,
});

const postfixSteps = [
  [1000000000, 'B', 'Billion'],
  [1000000, 'M', 'Million'],
  [1000, 'K', 'Thousand'],
].map(normalizePostfixSteps);

const formatPostFix = (num, step, digits, isFull) =>
  `${num.toFixed(digits)}${step[isFull ? 'fullPostfix' : 'postfix']}`;

const toRightPostfix = (num, digits, isFull) => (result, step) =>
  result === num && num >= step.num
    ? formatPostFix(num / step.num, step, digits, isFull)
    : result;

export const toPostfix = (num, digits = 2, isFull = false) =>
  num &&
  postfixSteps.reduce(toRightPostfix(num, digits, isFull), num).toString();

const isDate = val => val instanceof Date;

const isOther = () => true;

const toTestSuit = ([typeTest, valueTest]) => ({
  forType: typeTest,
  forValues: valueTest,
});

const testSuit = [
  [isOther, (prop1, prop2) => prop1 === prop2],

  [Iterable.isIterable, (prop1, prop2) => prop1.equals(prop2)],

  [isDate, (prop1, prop2) => prop1.getTime() === prop2.getTime()],
].map(toTestSuit);

const toRelevantTestResult = (prop1, prop2) => (lastTestExecuted, test) =>
  test.forType(prop1) ? test.forValues(prop1, prop2) : lastTestExecuted;

const isEqual = (prop1, prop2) =>
  testSuit.reduce(toRelevantTestResult(prop1, prop2));

export const isEqualProps = (props, nextProps, prevProps) =>
  prevProps && props.every(prop => isEqual(nextProps[prop], prevProps[prop]));

const topExpertsUrl = person =>
  ({
    hedgefund: '/hedge-funds/top',
  }[person.get('type')] || `/${person.get('type')}s/top`);

const profileUrl = person =>
  `${domain()}/${{
    hedgefund: 'hedge-funds',
  }[person.get('type')] || `experts/${person.get('type')}s`}/${person
    .get('name')
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')}`;

const personUrl = (person, type = 'profile') =>
  cond(
    [type === 'profile', profileUrl(person)],
    [type === 'topOfType', topExpertsUrl(person)],
  );

const toQueryStringPart = obj => a =>
  `${encodeURIComponent(a)}=${encodeURIComponent(obj[a])}`;

export const queryString = (url, args) =>
  `${url}?${Object.keys(args)
    .map(toQueryStringPart(args))
    .join('&')}`;

  
const isNasdaqPreprod = () => window.location.href.includes('qc');

const strUrl = (str, args?) =>
  ({
    plans: '/plans',
    topExperts: '/top-experts',
    blueSanpPayment: () =>
      queryString(`https://checkout.bluesnap.com/buynow/checkout/`, {
        ...args,
        browsertitle: 'Smart%20Portfolio%20%7C%20Nasdaq',
      }),
    fsPayment: () =>
      queryString(`https://${isNasdaqPreprod() ? "preprod4" : "www"}.tipranks.com/checkout/nasdaq/`, {
        ...args,
        browsertitle: 'Smart%20Portfolio%20%7C%20Nasdaq',
      })
  }[str]);

export const appRoute = (obj, ...args) =>
  cond(
    [
      ['analyst', 'blogger', 'insider', 'hedgefund'].includes(
        obj.get && obj.get('type'),
      ),
      () => personUrl(obj, ...args),
    ],
    [
      ['stock', 'etf'].includes(obj.get && obj.get('type')),
      () => getTickerUrl(obj),
    ],

    () => de(strUrl(obj, ...args)),
  );

export const groupIntoTwo = arr =>
  arr.groupBy((x, i) => (i % 2 === 0 ? 'even' : 'odd')).entrySeq();

export function imgSrc(obj, ...args) {
  return cond(
    [obj instanceof Person, () => personImgSrc(obj, ...args)],

    () => imgSrcString(obj, ...args),
  );
}

export function personProfileImgSrc(photoId: any | void, expertType?) {
  if (photoId)
    return `//az712682.vo.msecnd.net/expert-pictures/${photoId}_tsqr.jpg`;
  if (expertType === 'hedgefund') return imgSrc('hedgefundProfileFallback');
  return imgSrc('analystProfileFallback');
}

export function personImgSrc(person, type = 'profile') {
  return cond(
    [
      type === 'profile',
      () => personProfileImgSrc(person.get('photoId'), person.get('type')),
    ],

    [type === 'type', () => personTypeImgSrc(person.get('type'))],

    [type === 'sector', () => sectorImgSrc(person.getIn(['firm', 'sector']))],

    [
      type === 'randomInfo',
      () => `/images/personinfo/lock${getRandomArbitrary(1, 7)}.png`,
    ],
  );
}

const isFunction = obj => obj && {}.toString.call(obj) === '[object Function]';

export const isPromise = obj => typeof obj.then === 'function';

export const de = (obj, args?: any) => (isFunction(obj) ? obj(args) : obj);

export const car = list => list.get(0);

export const cdr = list => list.rest();

export const cons = (atom, list) => list.push(atom);

export const isZero = number => number === 0;

export const isNull = list => list.size === 0;

export const isEq = is;

export const cond = (...args) =>
  fromJS(args).reduce(
    (prev, curr) =>
      prev === null
        ? List.isList(curr)
          ? curr.size === 1
            ? de(curr.first())
            : curr.size === 2
              ? de(curr.first()) ? de(curr.get(1)) : null
              : null
          : de(curr)
        : prev,
    null,
  );

export const complement = fn => (...args) => !de(fn, args);

// TODO this exists in common/lib as well.
export const createQueryString = (params, url = '') => {
  if (!params) {
    return url;
  }
  const urlParts = url.split('?');
  const originalParams = urlParts[1] ? urlParts[1].split('&') : [];
  const newParams = Object.keys(params)
    .map(k => `${k}=${params[k]}`)
    .concat(originalParams)
    .join('&');
  return [urlParts[0], newParams].join('/?');
};

export const isCanadianTicker = (ticker: string) => !ticker ? false : ticker.toLowerCase().indexOf('tse') !== -1;

export function mediaQueryBetween(mediaQuery: MediaQuery, from: MediaQueryKey, to: MediaQueryKey) {
  return mediaQuery.get(from) && !mediaQuery.get(to);
}
/**
 * Utility function to enable the use case of splitting a store's
 * reducer, introduced in the public portfolio onboarding version.
 * The idea is that reducers and actions should live in a file next to
 * where they are used, not arbitrarily in some reducer/action file somewhere.
 */
export function composeReducers(...reducers) {
  return function composedReducer(state, action) {
    return reducers.reduce((state, reducer) => {
      return reducer(state, action);
    }, state);
  }
}