import * as React from 'react';
import { selectIsPermanentLoggedIn } from '../../common/auth/selectors';
import { getBlueSnapPaymentUrl, getFSPaymentUrl } from '../tr-payment';
import { BluesnapCustomParams } from '../tr-payment/types';

import * as styles from './style.istyl';

import ExternalRegister from '../tr-external-auth/signup-form.react';
import { AccountDetails, Broker } from './AccountDetails';
import { AlertsAndNewsletter, MailFrequency } from './AlertsAndNewsletter';
import { Feedback } from './Feedback';
import { LearningCenter } from './LearningCenter';
import { SynchronizeExistingPortfolio } from './SynchronizeExistingPortfolio';

import { ApiDecorator } from 'sp/browser/ApiDecorator/ApiDecorator.react';
import { history } from 'sp/browser/history';
import { ComponentState, EmailAlertSetting, ReduxProps } from '../../common/types';
import {
  selectEmailBombardmentServerState,
  selectPasswordChangeState,
  selectUserActualPortfolios,
  selectUserFollowedStocksCount,
  selectUserInfo,
} from '../dashboard/selectors';
import { BrokerId } from '../tr-connect-existing-portfolio/conf';
import {
  selectIsDisabledExperts,
  selectSettings
} from '../tr-dashboard-alerts-settings/selectors';


@ApiDecorator(
  'experts',
  // 'userPref',
  'userInfo',
  'alertsSettings',
  'portfolios',
  'stocks',
  'userFollowedStocks',
)
export class AccountSettings extends React.PureComponent<ReduxProps, {}> {
  private get nameLoading() {
    const { auth } = this.props;
    return Boolean(this.user.get('name_old'));
  }

  private onAuthenticatedDialog = () => {
    const { actions } = this.props;

    actions.identifyUser().then(() => {
      actions.closeDialog();
      history.push(`/smart-portfolio/account/settings`);
      actions.fetchPortfolios();
    });
  };

  private get user() {
    return this.props.auth.get('users').get('viewer').get('loggedInUser');
  }

  private onConnectExistingLoading = () => {
    const { actions } = this.props;
    actions.openDialog('analyzingPortfolio');
    // show proccessing import page in the background to log it in analytics
    history.push('/smart-portfolio/processing-import');
  };

  private onConnectExistingSuccess = async (brokerId: BrokerId) => {
    const { actions, myExperts } = this.props;

    // close dialog any way
    actions.closeDialog();

    // try to fetch portfolios and move to the newer one
    try {
      const { payload } = await (actions.fetchPortfolios as any)();

      const portfolios = payload.res; // TODO as Portfolio[];
      const portfolioId = portfolios.find(
        p => p.siteName && p.siteName.toLowerCase() === brokerId,
      ).id;
      actions.changePortfolio(portfolioId);

      history.push('/smart-portfolio/account/settings');
    } catch (e) {
      console.error('e', e);
    }
  };

  // handle done of loading by closing the 'analyzing portfolio' dialog
  onConnectExistingLoadingDone = () => {
    const { actions } = this.props;
    actions.closeDialog();
  };

  private onFeedbackSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    const { actions, auth } = this.props;
    actions.postForm({
      name: this.user.get('name') || 'A man has no name',
      email: this.user.get('email') || 'no@email.sadness',
      phone: this.user.get('phone'),
      typeId: 6,
      type: 'My Portfolio',
      title: 'Feedback from My Account Page',
      message: (e.currentTarget.message as HTMLTextAreaElement).value,
    });
  };
  private onClickManageStockNotifications = () =>
    this.props.actions.openDialog('alertsSettings');
  private onClickManageExpertNotifications = () => null;

  private onChangeEmailFrequency = (mailFrequency: MailFrequency) =>
    this.props.actions.changeEmailFrequency(
      mailFrequency === MailFrequency.AllRelevant,
    );

  private onPhoneChange = (e: React.FormEvent<HTMLFormElement>) =>
    this.props.actions.changePhone(e.currentTarget.tel.value);

  private onPasswordChange = (e: React.FormEvent<HTMLFormElement>) => {
    const oldPassword = e.currentTarget.oldPassword.value;
    const newPassword = e.currentTarget.newPassword.value;
    this.props.actions.changePassword({ oldPassword, newPassword });
  };

  private onNameChange = (e: React.FormEvent<HTMLFormElement>) =>
    this.props.actions.changeUserName(e.currentTarget.newName.value);

  private toggleEmailAlerts = (shouldBeActive: boolean) => {
    this.props.actions.toggleIsDisabledExperts(!shouldBeActive); // double negatives are fun
  };

  private reauthenticate = (broker: Broker) => {
    this.props.actions.openDialog('syncDialog', {
      broker: Broker[broker],
      isReauth: true,
    });
  };

  public render() {
    const {
      dashboard,
      actions,
      auth,
      alertsSettings,
      myExperts,
      contact,
    } = this.props;

    const memberSince = this.user.get('signupDate')
      ? new Date(this.user.get('signupDate'))
      : new Date();
    const name = this.user.get('name');
    const email = this.user.get('email');
    const plan = this.user.get('plan');
    const bypassEmailLimit = this.user.get('bypassEmailLimit');
    const bluesnapUrlGen = getFSPaymentUrl.bind(
      null,
      auth,
      plan,
      plan === 'premium',
    ) as <K extends keyof BluesnapCustomParams>(
        customOverride: Pick<BluesnapCustomParams, K>,
      ) => string;

    getFSPaymentUrl(auth, plan, plan === 'premium', { custom3: 'foo' });

    const synchronizedAccounts = selectUserActualPortfolios(dashboard)
      .filter(portfolio => !!portfolio.get('siteName'))
      .map(portfolio => ({
        broker: Broker[portfolio.get('siteName')],
        authentication: portfolio.get('tradeItSyncStatus'),
        id: portfolio.get('id'),
      }))
      .toJS();

    const stocksFollowing = selectUserFollowedStocksCount(dashboard);

    const expertsFollowing = myExperts.experts.filter(
      expert => expert.isAlertsOn,
    ).size;

    // const nextBillingDate = new Date('2017-10-28');
    // const lastFourDigits = '7236';

    const passwordServerState = selectPasswordChangeState(dashboard);
    const emailFrequencyServerState = selectEmailBombardmentServerState(
      dashboard,
    );

    const phoneNumber = this.user.get('phone');
    const areEmailAlertsDisabled = selectIsDisabledExperts(
      alertsSettings,
    ) as boolean;
    const emailAlertSettings: EmailAlertSetting[] = selectSettings(
      alertsSettings,
    )
      .filter(
        setting =>
          !!setting.get('val') && setting.get('id') !== 'ranking', // show only enabled // its not logical to show it outside "manage stock notfications" dialog
    )
      .toJS();

    const contactState: ComponentState = contact.success
      ? 'SUCCESS'
      : contact.loading ? 'LOADING' : 'INITIAL';

    const isPermanentLoggedIn = selectIsPermanentLoggedIn();

    if (process.env.THEME !== 'nasdaq' && !isPermanentLoggedIn) {
      return (
        <div className="tr-external-login-wrapper">
          <ExternalRegister
            onAuthenticated={this.onAuthenticatedDialog}
            type="screenerLogin"
            path="login"
          />
        </div>
      );
    }

    return (
      <main className={styles.accountSettings}>
        <div>
          <AccountDetails
            {...{
              name,
              memberSince,
              email,
              synchronizedAccounts,
              phoneNumber,
              plan,
              passwordServerState,
              bluesnapUrlGen,
            }}
            onPhoneChange={this.onPhoneChange}
            onNameChange={this.onNameChange}
            onPasswordChange={this.onPasswordChange}
            onReauthenticate={this.reauthenticate}
            userPic={selectUserInfo(dashboard).get('pictureUrl')}
          />
          <AlertsAndNewsletter
            emailAlertsActive={!areEmailAlertsDisabled}
            toggleEmailAlerts={this.toggleEmailAlerts}
            bypassEmailLimit={bypassEmailLimit}
            emailAlertSettings={emailAlertSettings}
            emailFrequencyServerState={emailFrequencyServerState}
            expertsFollowing={expertsFollowing}
            onChangeEmailFrequency={this.onChangeEmailFrequency}
            onClickManageExpertNotifications={
              this.onClickManageExpertNotifications
            }
            onClickManageStockNotifications={
              this.onClickManageStockNotifications
            }
            stocksFollowing={stocksFollowing}
          />
        </div>
        <div className={styles.sideBar}>
          <SynchronizeExistingPortfolio
            onLoading={this.onConnectExistingLoading}
            onSuccess={this.onConnectExistingSuccess}
            onLoadingDone={this.onConnectExistingLoadingDone}
          />
          <Feedback onSubmit={this.onFeedbackSubmit} formState={contactState} />
          <LearningCenter />
        </div>
      </main>
    );
  }
}
